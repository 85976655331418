footer {
  background-color: $white_color;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  @include responsive(tab-port) {
    font-size: 1.4rem;
  }
  @include responsive(phone) {
    font-size: 1.2rem;
  }
  @include responsive(phone-land) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .footer-nav {
      margin-top: 1rem;
    }
  }
}