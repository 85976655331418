.fancy-check {
  opacity: 0;
  left: 2rem;
  top: -1rem;

  & + label {
    &::after {
      content: '';
      position: absolute;
      top: .2rem;
      left: -4rem;
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 50%;
      background-color: $white_color;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    &::before {
      content: '\f00c';
      position: absolute;
      font-family: "Font Awesome 5 Free";
      top: .4rem;
      color: $green_color;
      font-size: 1.3rem;
      left: -3.5rem;
      z-index: 2;
      transition: all .15s ease-in-out;
      font-weight: 900;
    }

    &::before {
      visibility: hidden;
      transform: scale(0);
    }

  }

  &:checked + label.form-check-label::before {
    visibility: visible;
    transform: scale(1);
  }

  &--radio {
    & + label {
      font-weight: 300;
      cursor: pointer;

      &::after {
        top: 0;
        right: -3.5rem;
        left: unset;
      }

      &::before {
        content: '';
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        right: -3.1rem;
        top: .4rem;
        left: unset;
        transform: translateY(-50%);
        background-color: $black_color;
      }
    }
  }
}