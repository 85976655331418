.logo-nav {
  display: flex;
  justify-content: space-between;
  max-width: 67rem;
  margin: auto;
  @include responsive(phone-land) {
    margin: 0;
    max-width: 100%;
  }
  &__image {
    height: 11rem;

    @include responsive(phone-land) {
      height: 12rem;

      &:last-of-type {
        height: 10rem;
        margin-right: 2.5rem;
      }
      &:first-of-type {
        margin-left: 1.4rem;
      }
    }
  }
}

.socials-nav {
  position: absolute;
  top: 5rem;
  right: 0;
  border: 1px solid $white_color;
  padding: .5rem 1rem;

  @include responsive(phone-land) {
    visibility: hidden;
  }

  &__list {
    margin: 0;
    list-style: none;
  }

  &__item {
    text-align: center;
    margin: .5rem auto;
  }

  &__link {
    &, &:hover {
      text-decoration: none;
      color: $white_color;
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: $white_color;
    padding: .2rem 0;

    &--facebook {
      height: 2.2rem;
      width: 2.2rem;
    }
  }

}

.footer-nav {
  margin-left: 2rem;
  &__list {
    display: inline-flex;
    list-style: none;
  }
  &__item {
    &:not(:last-child) {
      &::after {
        content: '|';
        display: inline-block;
        margin: 0 .8rem 0 .6rem;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-family: inherit;
    &, &:hover {
      color: $black_color;
    }
  }

}