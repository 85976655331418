.color-dark-blue {
  color: $dark-blue_color;
}

.u-mt-n5 {
  margin-top: -5rem;
}

.u-mt-n6 {
  margin-top: -6rem;
}

.u-mr-n4 {
  margin-right: -4rem;
}

.u-mb-n7 {
  margin-bottom: -7rem;
}

.u-index-2 {
  z-index: 2;
}

.u-hidden-phone {
  display: block;
  @include responsive(phone-land) {
    display: none;
  }
}

.u-hidden {
  visibility: hidden;
}

.u-d-none--phone {
  display: block !important;
  @include responsive(phone) {
    display: none !important;
  }
}

.u-d-block--phone {
  display: none !important;
  @include responsive(phone) {
    display: block !important;
  }
}

.u-d-flex--phone {
  display: none !important;
  @include responsive(phone) {
    display: flex !important;
  }
}

.u-letter-spacing {
  letter-spacing: .1rem;
}

.playstation-logo {
  height: 5rem;
  margin: 1.5rem auto;
}

.u-text-underline {
  text-decoration: underline;
}

a.u-reset-link-tag {
  font-family: "Nissan Brand", Sans-Serif;
  text-decoration: underline;

  &, &:hover {
    color: $white_color;
  }
}

.u-color-bright-yellow {
  color: $bright-yellow_color !important;
}

.u-color-yellow {
  color: $yellow_color;
}

.u-text-capital {
  text-transform: capitalize;
}