.summary-table {
  margin: 5rem auto;
  @include responsive(phone) {
    margin: 2rem auto;
    max-width: 50rem;
  }


  &__body {
    margin: 1rem auto 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    font-weight: 300;
    @include responsive(phone) {
      margin-bottom: 1rem;
    }
  }

  &--cell &__body {
    grid-template-columns: repeat(2, 1fr);
    @include responsive(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--cell &__cell {
    background-color: $middle-blue_color;
    margin: .1rem;

  }

  &:not(.summary-table--cell) &__cell {
    &:not(:first-child) {
      display: flex;
      align-items: center;
      justify-content: center;
      @include responsive(phone) {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  &__cell {
    color: $white_color;
    padding: 1rem 2rem;
  }

  &__header {
    text-align: left;
    padding-left: 2rem;
    display: block;
  }

  &__row {
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr);
    background-color: $middle-blue_color;
    padding-top: .5rem;
    padding-bottom: .5rem;


    @include responsive(phone) {
      font-size: 1.4rem !important;
      h2 {
        font-size: 1.6rem !important;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: .2rem;
    }

    &--transparent {
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }

    &--transparent .summary-table__cell {
      padding-bottom: 0;

      @include responsive(phone) {
        display: flex;
        align-items: center;
      }
    }

  }

  &--responsive {
    display: none;
    @include responsive(phone) {
      display: grid;
    }
  }

  &--responsive &__body {
    width: 100%;
  }

  &--responsive &__row {
    grid-template-columns: 1fr;
  }

  &--desktop {
    @include responsive(phone) {
      display: none;
    }
  }

}