/* Nissan Brand */

@font-face {
  font-family: "Nissan Brand";
  src: url('../../fonts/NissanBrand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nissan Brand";
  src: url('../../fonts/NissanBrand-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: "Nissan Brand";
  src: url('../../fonts/NissanBrand-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: "Nissan Brand";
  src: url('../../fonts/NissanBrand-Bold.ttf') format('truetype');
  font-weight: 700;
}

.font-nissan {
  font-family: "Nissan Brand", sans-serif;
}

/* *** */

/* Lato*/

@font-face {
  font-family: "Lato";
  src: url('../../fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
}

.font-lato {
  font-family: "Lato", sans-serif;
}

/* *** */


/* Oswald*/

@font-face {
  font-family: "Oswald";
  src: url('../../fonts/Oswald-Medium.ttf') format('truetype');
  font-weight: normal;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

/* *** */

.heading-primary {
  font-size: 3.2rem;
}

.heading-secondary {
  font-size: 2.8rem;
}

.heading-tertiary {
  font-size: 2rem;
}

