.error-box {
  font-weight: bold;
  text-align: left;
  margin-top: 2rem;
  display: none;

  &--show{
    display: block;
  }

  &__label {
    background-color: $red_color;
    color: $white_color;
    padding: .1rem .4rem;
    border-radius: .2rem;
    margin-right: 1rem;
  }

  &__content {
    color: $red_color;
  }
}