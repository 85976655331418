.info-box {
  padding: 1.5rem;
  background-color: transparentize($black_color, .35);
  color: $white_color;
  width: 100%;
  &:not(:last-of-type){
    margin-bottom: 2rem;
  }
}

.info-box-wrapper {
  margin: 2rem auto;
}