.news {
  max-width: 50rem;
  margin: auto;
  a {
    .news__desc{
      color: $black_color;
      transition: color .15s ease;
    }
    .news__author{
      color: lighten($black_color,15);;
    }
    &:hover {
      text-decoration: none !important;
      .news__desc{
        color: lighten($black_color,35);
      }
    }
  }

  .news__image-box {
    height: 20rem;
    width: 100%;
    position: relative;
    margin-bottom: 1.6rem;
  }
  .news__image {
    height: 100%;
    position: relative;
    z-index: 2;
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
  .news__image-caption {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background: $light-blue_color;
    color: $white_color;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: .25rem .8rem;
  }
  .news__desc {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  $dark-gray: darken($grey_color,35);
  .news__tool-box{
    display: inline-flex;
    border-bottom: 1px solid $dark-gray;
    width: 100%;
  }
  .news__comment-box {
    margin-left: auto;
  }
  .news__comment-icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: $dark-gray;
    margin-top: .2rem;
  }
  .news__comment-number {
    color: $red_color;
    margin-left: -.2rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
}