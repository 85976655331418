.round-slider {
  position: relative;
  height: auto;
  width: 100%;
  margin: 3rem auto 10rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include responsive(phone) {
    margin-top: 0;
    width: 95%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -4rem;
    left: 0;
    height: .6rem;
    width: 100%;
    background-color: $white_color;
    border-radius: .5rem;
  }

  &__text {
    font-size: 1.35rem;
    text-transform: uppercase;
    font-weight: 300;
    color: $white_color;
    @include responsive(phone) {
      font-size: 1.2rem;
    }
  }

  &__item {
    width: 10rem;
    text-align: center;
    position: relative;
    @include responsive(phone) {
      visibility: hidden;
      height: .1rem;
    }
    @include responsive(phone-mini) {
      min-width: .5rem;
      width: 100%;
    }
  }

  &__item.active &__text {
    color: $yellow_color;
  }

  &__item.active &__circle, &__item.completed &__circle {
    visibility: visible;

    .round-slider__icon{
      visibility: visible;
    }
  }

  &__circle {
    visibility: hidden;
    @include responsive(phone) {
      visibility: visible;
      height: 3.5rem;
      width: 3.5rem;
    }
    @include responsive(phone-mini) {
      border-width: .3rem;
      height: 3rem;
      width: 3rem;
      bottom: -5.2rem;
    }
    position: absolute;
    bottom: -5.5rem;
    padding-top: .2rem;
    font-size: 2rem;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .5rem solid $white_color;
    border-radius: 50%;
    background-color: $grey_color;
  }

  &__icon {
    fill: $green_color;
    height: 1.8rem;
    width: 1.8rem;
    visibility: hidden;

    @include responsive(phone-mini) {
      height: 1.3rem;
      width: 1.3rem;
    }
  }

}