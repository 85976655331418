body.final-page {
  #common-page-slider {
    display: none;
  }

  #common-page-slider {
    @include responsive(phone) {
      display: block;
    }
  }

  #final-page-slider {
    @include responsive(phone) {
      display: none;
    }
  }
}