#days-counter {
  width: fit-content !important;
  margin: auto;
  display: block;
  height: 130px;
  width: 420px;
  @include responsive(phone-mini) {
    height: 70px !important;
  }
}

.flip-clock-wrapper {
  .flip-clock-divider {
    &:nth-child(1) {
      &:after {
        content: none !important;
      }
    }

    &:before, &:after {
      content: '';
      height: 2rem;
      width: .2rem;
      background: linear-gradient(to bottom, #ef768c 50%, #8e021e);
      position: absolute;
      top: 45%;
      z-index: 8;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
    }

    &:after {
      right: 0;
      left: inherit !important;
    }

    &.days {
      .flip-clock-label {
        left: 30px !important;
        @include responsive(phone-mini) {
          left: 19px !important;
        }
      }
    }

    &.hours {
      .flip-clock-label {
        left: 40px !important;
        @include responsive(phone-mini) {
          left: 30px !important;
        }
      }
    }

    &.minutes {
      .flip-clock-label {
        left: 27px !important;
        @include responsive(phone-mini) {
          left: 20px !important;
        }
      }
    }

    &.seconds {
      .flip-clock-label {
        left: 20px !important;
      }
    }

    .flip-clock-label {
      font-family: "Nissan Brand", sans-serif !important;
      bottom: -2rem !important;
      top: inherit !important;
      font-size: 16px !important;
      font-weight: bold !important;
      text-align: center !important;
      height: 1.5rem !important;
      right: 0 !important;
      text-transform: uppercase !important;

      @include responsive(phone-mini) {
        font-size: 12px !important;
        bottom: 2rem !important;
      }
    }
  }


  .flip-clock-dot {
    display: none;
  }

  ul {
    margin: 0 !important;
    width: 45px !important;
    @include responsive(phone-mini) {
      line-height: 57px !important;
      height: 60px !important;
      width: 30px !important;
    }

    &:nth-child(12) {
      &:after {
        content: '';
        height: 2rem;
        width: .2rem;
        background: linear-gradient(to bottom, #ef768c 50%, #8e021e);
        position: absolute;
        top: 45%;
        z-index: 8;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
      }
    }

    li {
      .inn {
        border-radius: 0 !important;
        background: linear-gradient(to top, #b60327 5%, #c00329) !important;
        text-shadow: none !important;
        font-family: "Oswald", sans-serif !important;
        font-size: 60px !important;
        @include responsive(phone-mini) {
          font-size: 45px !important;
        }
      }

      .down {
        .inn {
          background: linear-gradient(to bottom, #ed5f78 10%, #cf032c) !important;
        }
      }
    }
  }
}