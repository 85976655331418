.blurred-hr {
  height: 2rem;
  background-color: transparent;
  position: relative;
  width: 100%;
  margin: 2rem auto;
  overflow: hidden;

  &::after {
    content: '';
    height: 2rem;
    width: 90%;
    border-radius: 30%;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    box-shadow: 1px 1px 9px 3px $black_color;
  }
}