.match-box {
  max-width: 100%;
  padding: .5rem 2rem 2rem;
  background: $blue-gradient;
  margin-top: 2.5rem;
  @include responsive(phone) {
    background: transparent;
    padding: .5rem 0 2rem;
    margin-top: 0;
  }

  &__header {
    text-align: center;
    margin: 1rem;
  }

  &__title {
    color: $yellow_color;
    font-size: 2.2rem;
    text-transform: capitalize;
  }

  &__body {

  }

  &__separator {
    height: .5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
    margin: 0 1rem;
    background-color: $white_color;
  }

  &__team-name {
    color: $white_color;
    font-size: 2rem;
    text-transform: capitalize;
    margin-bottom: 0;
    @include responsive(phone) {
      margin: 0 auto;
    }
    @include responsive(phone-mini) {
      font-size: 1.5rem;
    }
  }

  &__team-logo {
    width: auto;
    height: 8rem;
    object-fit: contain;
    transform: translateY(-40%);
  }

  &__block {
    height: 6rem;
    justify-content: space-between;
    padding: 0 5rem;
    background-image: url("../../img/diag-bars-pattern.png");
    background-repeat: repeat;
    margin: 1rem 0;
    @include responsive(tab-port) {
      padding: 0;
    }
    @include responsive(phone-land) {
      padding: 5rem 0;
    }
  }

  *[class$="-part"], &__block {
    display: inline-flex;
    width: 100%;
    align-items: center;
  }

  *[class$="-part"] {
    padding: 1rem;
  }

  &__label-block {
    display: flex;
    align-items: center;

    @include responsive(phone) {
      flex-direction: column;
      justify-content: center;
      label {
        margin: 0;
      }
    }
  }

  .invalid-feedback {
    @include responsive(phone) {
      font-size: 1rem;
    }
  }

  &__left-part {
    justify-content: flex-end;

    input {
      order: 1;
      margin-left: 2rem;
    }

    @include responsive(phone) {
      flex-direction: column;
      align-items: flex-end !important;
      input {
        order: 0;
      }
      .match-box__label-block {
        order: 1 !important;
      }
    }
  }

  &__left-part &__label-block {
    order: 0;
    margin-left: auto;
    justify-content: flex-end;

    label {
      order: 1;
      margin-left: 2rem;
    }

    .invalid-feedback {
      order: 0;
      text-align: right;
    }

    @include responsive(phone) {
      align-items: flex-end;
      text-align: right;
      label {
        order: 0;
      }
      .invalid-feedback {
        order: 1;
      }
    }

  }

  &__right-part {
    justify-content: flex-start;

    input {
      order: 0;
      margin-right: 2rem;
    }

    @include responsive(phone) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  &__right-part &__label-block {
    order: 1;
    margin-right: auto;
    text-align: left;
    justify-content: flex-start;

    label {
      order: 0;
      margin-right: 2rem;
    }

    .invalid-feedback {
      order: 1;
      text-align: left;
    }

    @include responsive(phone) {
      align-items: flex-start;
    }

  }

  &__score {
    background-color: $white_color;
    height: 4rem;
    width: 4rem;
    border-radius: 1rem;
    border: none;
    text-align: center;
    font-size: 2.2rem;
    font-family: Oswald, "Open Sans", Sans-Serif;
    color: darken($grey_color, 35);

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      appearance: none;
    }

    &:focus {
      outline: none;
    }

    &.is-invalid {
      background: $white_color !important;
      padding: 1rem !important;
    }
  }

  &--eliminatory {
    background-image: url("../../img/diag-bars-pattern.png");
    border: 2rem solid $middle-blue_color;
    padding: 2.5rem;
    color: $white_color;
    @include responsive(phone) {
      background: transparent;
      border: none;
    }
  }

  &__selector-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.6rem;
    @include responsive(phone) {
      flex-direction: column;
    }
  }

  &__errors-box {
    max-width: 30rem;
    max-width: fit-content;
    margin: auto;

    //@include responsive(phone-mini) {
    //  max-width: 100%;
    //}
  }

  &__selector-box {
    flex-basis: 45%;
    max-width: 35rem;
    @include responsive(phone) {
      width: 100%;
      margin: 1rem auto;

      .match-box__label-error {
        text-align: center;
      }
    }
  }

  &__selector {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;

    &--invalid {
      border: 2px solid $red_color;
    }

    select {
      cursor: pointer;
      padding: .5rem 1rem;
      background-color: $white_color;
      appearance: none;
      width: 100%;
      height: 100%;

      &:focus {
        outline: none;
      }
    }

    &::after {
      position: absolute;
      content: '\f054';
      font-family: "Font Awesome 5 Free";
      color: $black_color;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.8rem;
      font-weight: 900;
    }
  }

  &__vs-label {
    text-transform: capitalize;
    margin: 0 2rem;
    font-size: 2rem;
    color: $white_color;
    width: 2rem;
    min-width: 2rem;
    max-width: 2rem;
    @include responsive(phone) {
      &--hidden {
        display: none;
      }
    }
  }

}