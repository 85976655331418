.entry-page {
  @include responsive(phone) {
    padding: 0;
  }

  .invalid-feedback {
    font-weight: bold;
  }

  &__form-wrapper {
    color: $white_color;
    max-width: 65rem;
    margin: 4rem auto;
    font-size: 1.4rem;
    @include responsive(phone) {
      margin-bottom: 0;
    }
  }

  @include responsive(phone) {
    .blurred-hr {
      display: none;
    }

  }

  &__field-wrapper {
    flex-direction: column;
    width: 100%;

    & > .row {
      width: 100%;
      margin: 0;
    }

    &, & input {
      font-size: 1.5rem;
    }

    input {
      text-align: center;
      flex-basis: 100% !important;
    }

    .col {
      max-width: fit-content;
      padding: 0;
      display: inline-block;
      align-self: center;

      @include responsive(phone) {
        max-width: 100% !important;
      }

      &:first-of-type, &:nth-child(3) {
        input {
          max-width: 7rem;
          @include responsive(phone) {
            max-width: 100%;
          }
        }
      }

      &:nth-child(even) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      label {
        margin: 0 1rem;
        max-width: fit-content;
      }
    }

    @include responsive(phone) {

      & > .row {
        flex-direction: column;

      }
      .col {
        justify-content: flex-start !important;
        align-self: baseline;
        margin-bottom: 1rem;

        &:nth-of-type(2) {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &:nth-child(4), &:nth-child(2) {
          display: none !important;
        }

        input, textarea {
          text-align: left;
        }
      }
      label {
        margin: 0;
      }
    }
  }

  &__field-error-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__form {
    background: $blue-gradient;
    border: 1.5rem solid $middle-blue_color;
    background: url("../../img/diag-bars-pattern.png"), $light-blue-gradient;
    background-repeat: repeat;
    padding: 5rem 4rem;
    margin-top: 5rem;

    input::placeholder, textarea::placeholder, select option:first-of-type {
      color: darken($grey_color, 15);
      font-weight: 300;
      visibility: hidden;

      @include responsive(phone) {
        visibility: visible;
      }
    }

    .form-group.row {
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin: 1rem 0;

      & * {
        font-size: 1.5rem;
      }

      & > .col {
        padding: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-of-type {
          justify-content: flex-start;
          @include responsive(phone) {
            display: none;
          }
        }

        &:last-of-type {
          flex-basis: 65%;

          @include responsive(phone-land) {
            flex-basis: 60%;
          }
        }
      }

      label {
        font-weight: 300;
        margin-bottom: 0;

        &:not(.form-check-label) {
          text-align: right;
        }

        &.form-check-label {
          cursor: pointer;
          max-width: 25rem;
          display: block;
          margin-right: auto;
          position: relative;
          margin-left: 4rem;
        }

        .invalid-feedback {
          display: none !important;
        }

      }

      input, textarea, select {
        height: 4rem;
        border-radius: 1rem;
        padding: .5rem 1rem;
        background-color: $white_color;
        @include responsive(phone) {
          height: 5rem;
        }
      }

      textarea {
        padding-top: .8rem;
      }
    }

    @include responsive(phone) {
      background: $bg-blue_color;
      border: none;
    }
  }

  &__submit {
    margin-top: 2rem;
    padding: 1rem 6.5rem;
    font-size: 2rem;
    border-radius: 1rem;
  }

  &__form-content {
    margin: 4rem auto 1rem;
    max-width: 45rem;
  }

  &__question-box {
  }

  &__form-desc {
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    margin: auto;
  }

  &__frame-box {
    margin-top: 2rem;
    margin-bottom: 6rem;
    @include responsive(phone) {
      padding: 0 2rem;
      margin-bottom: 0rem;
    }

    transform: translateY(2rem);
    opacity: 1;
    visibility: visible;
    transition: transform .2s ease-in-out, opacity .25s ease-in-out, height .15s linear;

    &--hidden {
      height: 0;
      transform: translateY(0);
      opacity: 0;
      margin-bottom: 0;
      visibility: hidden;
      @include responsive(phone) {
        margin-bottom: -5rem;
      }
    }

    label {
      display: block;
      font-weight: 300;
    }

    input {
      max-width: 50rem;
      margin: auto;
      font-size: 2.5rem;
      border-radius: .5rem;
      background-color: $white_color;
      height: 4.5rem;
      padding: 1rem;
      text-align: center;

      &::placeholder {
        font-family: "Lato", "Nissan Brand", Sans-Serif;
        text-align: center;
      }
    }

    @include responsive(phone) {
      input {
        font-size: 2rem;
      }
      label {
        font-size: 2rem;
      }
    }
  }

  .match-box__selector {
    margin: 0;
    flex: 1 1 100%;
    max-width: 100%;
  }

  &__column-check {
    margin-top: 2rem;
    align-items: flex-start !important;

    .form-check {
      padding-left: 0;
    }
  }

}