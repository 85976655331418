body.home-page {
  #wrapper {
    background: url("../../img/bg.jpg") center top no-repeat;
    background-size: contain;
    @include responsive(tab-port) {
      background-image: linear-gradient(to bottom, transparent 60vw, $bg-dark-blue_color 80vw),
      url("../../img/bg-responsive.jpg");
      background-position-y: -24rem;
      background-color: $bg-dark-blue_color;
    }
    @include responsive(phone-land) {
      background-image: linear-gradient(to bottom, transparent 85vw, $bg-dark-blue_color 110vw),
      url("../../img/bg-responsive.jpg");
      background-size: 130%;
      background-position-y: -18rem;
    }
    @include responsive(phone) {
      background-image: linear-gradient(to bottom, transparent 100vw, $bg-dark-blue_color 130vw),
      url("../../img/bg-responsive.jpg");
      background-size: 145%;
    }
    @include responsive(phone-mini) {
      background-position-y: -10rem;
    }

    & > main.container {
      @include responsive(tab-port) {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }

    .swiper-container {
      .swiper-button-prev, .swiper-button-next {
        &::after {
          color: $white_color;
          background: transparentize($black_color, .7);
          padding: 1.5rem 3rem;
        }
      }

      .swiper-button-next::after {
        transform: translateX(-2rem);
      }

      .swiper-button-prev::after {
        transform: translateX(2rem);
      }
    }

  }

}

#hero-section {
  margin-top: 2.5rem;

  .hero-welcome-text {
    @include responsive(phone-land) {
      font-size: 2.2rem;
      letter-spacing: .1rem;
    }
  }

  .hero-image-wrapper {
    margin-bottom: -7rem;
    z-index: 2;

    @include responsive(tab-port) {
      margin-bottom: 0;
    }
  }

  .hero-info-text {
    max-width: 55rem;
    @include responsive(tab-land) {
      max-width: 45rem;
    }
    @include responsive(phone-land) {
      letter-spacing: .1rem;
      max-width: 32rem;
    }
    margin: 1.5rem auto;
  }

  .counter-header {
    max-width: 35rem;
    margin: 0 auto;
    @include responsive(tab-port) {
      display: none;
    }

    &--responsive {
      display: none !important;
      @include responsive(tab-port) {
        display: block !important;
      }
    }
  }
}

.hero-cars-image {
  height: auto;
  width: 90vw;
  max-width: 65rem;
}

#cta-section {
  .cta {
    &-header1 {
      max-width: 50rem;
      margin: 0 auto;
    }

    &-header2 {
      max-width: 35rem;
      margin: 0 auto;
    }

    &-responsive-view {
      display: none;

      .btn--link.btn--white {
        padding: 1.5rem 10rem 1.5rem 3rem;
      }

      @include responsive(tab-port) {
        display: block;
      }
    }

    &-desktop-view {
      display: block;
      @include responsive(tab-port) {
        display: none;
      }
    }
  }
}

#news-section {
  @include responsive(phone-land) {
    .playstation-promo__image {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  background-color: $grey_color;
  padding: 5rem 1rem;
}

main.container {
  @include responsive(tab-port) {
    padding: 0;
  }
  @include responsive(tab-port) {
    max-width: 70rem;
  }
}