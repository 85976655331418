.summary {
  &__wrapper {
    color: $white_color;
    max-width: 85rem;
    margin: auto;
    padding-bottom: 10rem;
    @include responsive(phone) {
      padding-bottom: 4rem;
    }
  }

  &__sub-title1 {
    font-weight: 300;
    max-width: 58rem;
    margin: 2rem auto 2.5rem;
  }

  &__sub-title2 {
    color: $bright-yellow_color;
  }

}

body.summary-page {
  #wrapper {
    background-image: url("../../img/bg-prediction.jpg") !important;
  }
}