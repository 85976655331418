.prediction-wrapper {
  max-width: 90rem;
  margin: 10rem auto 8rem;
  @include responsive(phone) {
    margin: 2rem auto 5rem;
  }

  .blurred-hr {
    &::after {
      width: 80%;
    }
  }

  &__content {
    margin-top: 4rem;
    margin-bottom: 10rem;
    @include responsive(phone) {
      margin-bottom: 0;
    }
  }

  &__title {
    color: $white_color;
    font-weight: bold;
  }

  &__sub-title {
    color: $yellow_color;
    font-weight: 300;
  }

  &__title, &__sub-title {
    text-transform: uppercase;
  }

}

body[class$="-page"] {
  &:not(.final-page) {
    #final-page-slider {
      @include responsive(phone) {
        display: none;
      }
    }
  }
}
