.playstation-promo {
  padding: 0 2rem;
  &__title {
    font-size: 2.5rem;
    color: $bg-blue_color;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    @include responsive(phone){
      font-size: 2rem;
    }
  }

  &__image-container {
    &:first-of-type {
      img {
        margin-left: auto;
      }
    }

    &:last-of-type {
      img {
        margin-right: auto;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    max-width: 25rem;
    object-fit: contain;
  }
}