.btn {
  width: auto;
  width: fit-content;
  border-radius: 1.5rem;
  @include responsive(tab-port) {
    border-radius: .8rem;
  }
  text-transform: uppercase;
  font-family: inherit;
  transition: all .2s ease;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  &--cta, &--cta-round {
    margin: 0 auto;
    font-family: inherit;
    letter-spacing: .15rem;
    @include responsive(phone-land) {
      font-size: 1.8rem;
    }
  }

  &--cta {
    font-size: 2.5rem;
    font-weight: bold;
    padding: .5rem 5rem;
    width: fit-content;
  }

  &--cta-round {
    font-size: 2rem;
    padding: 1rem 3rem;
    font-weight: 300;
  }

  &--link {
    position: relative;
    font-size: 1.2rem;
    padding: 1.2rem 5rem 1.2rem 2rem;
    @include responsive(phone-land) {
      font-size: 1.3rem;
    }

    &::before {
      content: ">";
      font-size: 2rem;
      position: absolute;
      top: 45%;
      right: 2rem;
      transition: all .3s ease-in;
      transform: translateY(-50%);
    }
  }

  &--red {
    background-color: $red_color;

    &, &:hover {
      color: $white_color;
    }

    &:hover {
      background-color: darken($red_color, 3);
    }
  }

  &--white {
    background-color: $white_color;
    color: $dark-blue_color;
    font-weight: bold;

    &:hover {
      background-color: darken($white_color, 3);
      color: $dark-blue_color;
    }
  }

  &--green {
    background-color: $green_color;

    &, &:hover {
      color: $white_color;
    }

    &:hover {
      background-color: darken($green_color, 3);
    }
  }
}