*[class$="-page"]:not(.home-page) {
  #wrapper {
    background-image: linear-gradient(to bottom, transparent 45vw, $bg-blue_color 60vw),
    url("../../img/bg-prediction.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center -38rem;

    @include responsive(tab-land) {
      background-position-y: -35rem;
      background-size: 120%;
    }
    @media (max-width: 1024px) {
      background-image: linear-gradient(to bottom, transparent 50vw, $bg-blue_color 80vw),
      url("../../img/bg-prediction.jpg");
      background-position-y: -28rem;
      background-size: 130%;
    }
    @include responsive(tab-port) {
      background-image: linear-gradient(to bottom, transparent 70vw, $bg-blue_color 95vw),
      url("../../img/bg-prediction.jpg");
      background-size: 145%;
    }
    @include responsive(phone-land) {
      background-image: linear-gradient(to bottom, transparent 80vw, $bg-blue_color 95vw),
      url("../../img/bg-prediction.jpg");
      background-size: 160%;
      background-position-y: -25rem;
    }
    @include responsive(phone) {
      background-image: linear-gradient(to bottom, transparent 90vw, darken($bg-blue_color, 5) 115vw),
      url("../../img/bg-prediction.jpg");
      background-size: 180%;
      background-position-y: -15rem;
    }
    @include responsive(phone-mini) {
      background-image: linear-gradient(to bottom, transparent 90vw, darken($bg-blue_color, 5) 115vw),
      url("../../img/bg-prediction.jpg");
      background-size: 190%;
      background-position-y: -15rem;
    }
    @media (max-width: 420px) {
      background-image: linear-gradient(to bottom, transparent 100vw, darken($bg-blue_color, 5) 125vw),
      url("../../img/bg-prediction.jpg");
      background-position-y: -10rem;
      background-size: 210%;
    }
    @media (max-width: 380px) {
      background-image: linear-gradient(to bottom, transparent 100vw, darken($bg-blue_color, 5) 145vw),
      url("../../img/bg-prediction.jpg");
      background-position-y: -5rem;
      background-size: 215%;
    }
  }
}