.skewed-box {
  transform: skewY(-5deg);
  overflow: hidden;
  height: 55rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include responsive(tab-port) {
    height: 45rem;
    &, & > *, &::after {
      transform: skewY(0deg) !important;
    }
  }
  @include responsive(phone-land) {
    height: 30rem;
  }

  .playstation-logo {
    margin: 2rem auto;
    @include responsive(tab-port) {
      margin: 0 auto 5rem;
    }
    @include responsive(phone-land) {
      height: 3.5rem;
      margin-bottom: 1.5rem;
    }

  }

  &::after {
    content: '';
    background: url("../../img/stadium.jpg") center -3rem no-repeat;
    background-size: cover;
    position: absolute;
    top: -5rem;
    left: 0;
    z-index: 1;
    height: 120%;
    width: 100%;
    @include responsive(tab-land) {
      top: -4rem;
    }
  }

  & > *, &::after {
    transform: skewY(5deg);
  }

  & > * {
    position: relative;
    z-index: 2;
  }
}
