// Abstracts
@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/functions";
// Vendors
@import "vendors/bootstrap";
@import "vendors/flipclock";
@import "vendors/swiper";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/metroui.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
// Base styles
@import "base/typography";
@import "base/reset";
@import "base/utilities";
// Layouts
@import "layouts/footer";
@import "layouts/prediction-wrapper";
@import "layouts/playstation-promo";
// Components
@import "components/buttons";
@import "components/navbar";
@import "components/skewed-box";
@import "components/car-detail";
@import "components/news";
@import "components/round-slider";
@import "components/match-box";
@import "components/blurred-hr";
@import "components/fancy-check";
@import "components/info-box";
@import "components/summary-table";
@import "components/error-box";
// Pages
@import "pages/home";
@import "pages/fourth-round";
@import "pages/entry-page";
@import "pages/common-bg";
@import "pages/final-page";
@import "pages/summary-page";
// Themes
@import "themes/dark";
@import "themes/light";
