@import "~swiper/css/swiper.min.css";

.swiper {
  &-container {
    width: 100%;
    height: auto;
  }
  &-slide {
    text-align: center;
  }
}