.car-detail {
  text-transform: uppercase;
  color: $white_color;
  width: auto;
  max-width: 20rem;
  //@include responsive(phone) {
  //  max-width: 20rem;
  //}
  text-transform: uppercase;
  &__model {
    font-size: 1.8rem;
  }
  &__info {
    font-size: 1.2rem;
  }
  &__price {
    font-size: 2.5rem;
    font-weight: bold;
  }
  &__image {
    height: auto;
    max-height: 20rem;
    width: 100%;
    max-width: 35rem;
    display: block;
    margin: auto;
    object-fit: contain;
    object-position: center;
    @include responsive(phone-land){
      height: 30rem;
    }
    @include responsive(phone-land){
      max-height: 15rem;
    }
  }
}